import React from 'react'
import './Possibility.css';
import possibilityImage from '../../assets/logo512.png';

const Possibility = () => {
    return(
        <div className="rkt__possibility section__padding" id="possibility">
            <div className="rkt__possibility-image">
                <img src={possibilityImage} alt="possibility" />
            </div>

        <div className="rkt__possibility-content">
            <h4>Request Early Access to Get Started</h4>
            <h1 className="gradient__text">The possibilities are endless</h1>
            <p>Join the DAO today to get discounts on services, exclusive whitelist opportunities, NFT airdrops, and more!</p>
            <h4>Request Early Access to Get Started</h4>
        </div>
        </div>
    )
}

export default Possibility