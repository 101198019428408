import React from 'react'
import './Service.css'

const Service = ({ title, text }) => {
    return(
        <div className="rkt__services-container__feature">
            <div className="rkt__services-container__feature-title">
            <div />
            <h1>{title}</h1>
        </div>
        <div className="rkt__services-container__feature-text">
           <p>{text}</p>
        </div>
        </div>
    )
}

export default Service