import React from 'react'
import { Service } from '../../components';
import './services.css';

const services = () => {
    return(
        <div className="rkt__services section__margin" id="services">
            <div className="rkt__services-feature">
                <Service title="Solana Services" text="Whether you require basic one-time servies such as a logo, graphic, setting up a token, or more long-term solutions like websites or dApp solutions, we've got you covered."/> 
            </div>
            <div className="rkt__services-heading">
                <h1 className="gradient__text">Need to spice things up? Put some SAUCE on it!</h1>
                <p>Explore Services Powered By Rocketsauce</p>
            </div>
            <div className="rkt__services-container">
                <Service title="A LA CARTE SERVICES" text="SPL-TOKEN Creation, Web Graphics, Photoshop, NFT Generation, CandyMachine Setup and Deployment, and more!"/>
                <Service title="BASIC PACKAGE" text="The Barebones of what you need to launch your Solana project: NFT Generation, CandyMachine Mint Setup with Website, and Token/Coin Generation and Graphics"/>
                <Service title="PLATINUM PACKAGE" text="Everything in the basic package, plus full website creation, discord bot setup and verification and fully funtional storefront"/>
            </div>
        </div>
    )
}

export default services