import React from 'react'
import { Service } from '../../components';
import './Features.css';

const featuresData = [
    {
        title: 'Websites',
        text: 'Full-function Web3 React Based Sites (Like this one!) keep your users engaged',
    },
    {
        title: 'Graphics',
        text: 'From Twitter/Discord banners and GIFs to SPL-Token and Whitepaper Design, we cover the gamut in developing amazing graphics for you',
    },
    {
        title: 'NFT Generation',
        text: 'A to Z NFT Collection Creation. We will generate THOUSANDS of unique NFTs using your individual art layers. Do you already have art? We can add Metadata and build a CandyMachine & site for you to mint them from!',
    },
    {
        title: 'Marketing',
        text: 'We offer the fastest produced, most engaging marketing videos in Solana space. Twitter Reveals, Hype Videos and more - we do it all!',
    },
]


const Features = () => {
    return(
        <div className="rkt__features section__padding" id="features">
            <div className="rkt__features-heading">
                <h1 className="gradient__text">WEB3 PROJECTS IN THE PALM OF YOUR HAND</h1>
                <p>Contact Us To Get Started</p>
            </div>
            <div className="rkt__features-container">
            {featuresData.map((item, index) => (
                <Service title={item.title} text={item.text} key={item.title + index} />
            ))}
            </div>
        </div>
    )
}

export default Features