import * as React from 'react';
import { useState } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import './NavBar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/banner-plain.png';


const Menu = () => (
    <>
    <p><a href="#home">Home</a></p>
    <p><a href="#brands">What is RocketDAO?</a></p>
    <p><a href="https://mint.rocketsauce.xyz">Mint Now!</a></p>
    <p><a href="https://www.youtube.com/channel/UCpU4uN4PJq5Mh_MkFC5hYKQ">YouTube</a></p>
    <p><a href="#services">Services</a></p>
    </>
)

const NavBar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);

    return(
        <div>
           <div className="rkt__navbar">
               <div className="rkt__navbar-links">
                <div className="rkt__navbar-links_logo">
                    <img src={logo} alt="logo" />
                </div>

               <div className="rkt__navbar-links_container">
                <Menu />
                </div>
            </div>

            <div className="multibutton">
                        <WalletMultiButton />
            </div>


            <div className="rkt__navbar-menu">
                {toggleMenu
                ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
                }
                {toggleMenu && (
                    <div className="rkt__navbar-menu_container shadow-drop-2-tb">
                        <div className="rkt__navbar-menu_container-links">
                         
                        <Menu />
                
                        </div>
                    </div>
                )
                }
            </div>
            </div>
        </div>
    )
}

export default NavBar