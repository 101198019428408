import React from 'react'
import './Brand.css';
import { DapperDucks, Shibas, MHAC, TombStoned } from './imports';

const Brand = () => {
    return(
        <div className='rkt__brand-wrapper'>
        <div className="rkt__brand-header">
            <p>SOME NOTABLE PROJECTS USING ROCKET POWER:</p>
        </div>
        <div className='rkt__brand section' id="brands">
           
            <div className="rkt__brand-items">
            <span data-text="Dapper Ducks" className="tooltip">
            <div>
                <img src={DapperDucks} alt="Dapper Ducks" />
            </div>
            </span>
            <span data-text="Stoned Shiba Society" className="tooltip">
            <div>
                <img src={Shibas} alt="Stoned Shiba Society" />
            </div>
            </span>
            <span data-text="Mile High Ape Club" className="tooltip">
            <div>
                <img src={MHAC} alt="Mile High Ape Club" />
            </div>
            </span>
            <span data-text="TombStoned High Society" className="tooltip">
            <div>
                <img src={TombStoned} alt="TombStoned High Society" />
            </div>
            </span>
            </div>
        </div>
        </div>
    )
}

export default Brand