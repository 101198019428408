import React from 'react'
import './CTA.css'

const CTA = () => {
    return(
        <div className="rkt__cta">
            <div className="rkt__cta-content">
                <p>Request Early Access to Get Started</p>
                <h3>Mint Your RocketDAO Pass Today & Start Enjoying Your Benefits!</h3>
            </div>
            <div className="rkt__cta-button">
                <button type="button">MINT NOW</button>
                </div>    
        </div>
    )
}

export default CTA