import React from 'react'
import './Header.css';
import sauce from '../../assets/RKT-1.gif';


const Header = () => {
  return (
    <div className="rkt__header section_padding" id="home">
      <div className="rkt__header-content">
        <h1 className="gradient__text">Welcome to RocketDAO</h1>
        <p>Adding some sauce to your Solana one project at a time!</p>
        <div className="rkt__header-content_input">
         {/* <input type="number" placeholder="How Much SOL To Send?"></input> */}
         <div className="rkt__header-content_input-text">
         MINT IS LIVE! CLICK TO VISIT THE MINT PAGE &gt; &gt; &gt;
         </div>
         <button className="rkt__button" type="button" onClick={event =>  window.location.href='https://mint.rocketsauce.xyz/'}>MINT</button>
          </div>
          <div className="rkt__header-content_people">
            
            <p>come join the others in the DAO!</p>
          </div> 
          </div>
          <div className="rkt__header-image">
            <img className="rkt-1-ani-image" src={sauce} alt="RKT-1 NFT" />
          </div>
      </div>
     
  )
}

export default Header