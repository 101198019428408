import React from 'react';
import './Footer.css';
import rktLogo from '../../assets/PBRSBanner.png';

const Footer = () => {
    return(
        <div className="rkt__footer section__padding">
            <div className="rkt__footer-heading">
                <h1 className="gradient__text">Join The Discord!</h1>
            </div>

            <div className="rkt__footer-btn">
                <a href="https://discord.gg/J3BgFWxh5x">
                <p>Request Access</p>
                </a>
            </div>

            <div className="rkt__footer-links_logo">
                    <img src={rktLogo} alt="logo" />
                </div>

            <div className="rkt__footer-links">
               

                <div className="rkt__footer-links_div">
                    <h4>Social</h4>
                    <a href="https://twitter.com/SOLProprietor"><p>Twitter</p></a>
                    <a href="https://discord.gg/J3BgFWxh5x"><p>Discord</p></a>
                    <a href="https://instagram.com/cleancapture"><p>Instagram</p></a>
                    <a href="#home"><p>Contact</p></a>
                </div>

                <div className="rkt__footer-links_div">
                    <h4>DAO Links</h4>
                    <a href="#mint"><p>Mint</p></a>
                    <a href="https://staking.dapperducks.netlify.app"><p>Staking</p></a>
                    <a href="https://famousfoxes.com/tokenmarket"><p>Token Market</p></a>
                    <a href="https://pondhub.vercel.app"><p>Auctions</p></a>
                </div>

                <div className="rkt__footer-links_div">
                    <h4>Get In Touch</h4>
                    <a href="mailto:0xrocketsauce@gmail.com"><p>0xRocketsauce@gmail.com</p></a>
                    <a href="http://github.com/SOLProprietor"><p>Github/SOLProprietor</p></a>
                </div>

            </div>

            <div className="rkt__footer-copyright">
            <p>© 2022 Rocketsauce Studios. All rights reserved.</p>
            </div>

        </div>
    )
}

export default Footer